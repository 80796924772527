@tailwind base;
@tailwind components;
@tailwind utilities;

.word-animation {
  padding: 2em;
}
.v-slider-frame {
  border: 1px solid #4bb3fd;
  height: 50px;
  overflow: hidden;
  text-align: center;
}
ul.v-slides {
  list-style-type: none;
  transform: translateY(50px);
  padding: 0;
}
.v-slide {
  font-size: 24px;
  line-height: 50px;
  color: #4bb3fd;
}

.space {
  display: inline-block;
  width: 0.5em; /* Boşluk genişliğini kontrol eder */
}
@font-face {
  font-family: "Copperplate";
  src: url("/public/fonts/CopperplateCC-Bold.ttf") format("truetype");
}
@layer base {
  @font-face {
    font-family: "Copperplate";
    src: url("/public/fonts/CopperplateCC-Bold.ttf") format("truetype");
    font-weight: bold;
  }

  @font-face {
    font-family: "Lato";
    src: url("/public/fonts/Lato-Regular.ttf") format("truetype");
    font-weight: normal;
  }

  @font-face {
    font-family: "Lato";
    src: url("/public/fonts/Lato-Bold.ttf") format("truetype");
    font-weight: 700;
  }
}


.fixed.inset-0 {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto; /* Dikey kaydırma için */
}

.modal-content {
  max-height: 90vh; /* Modal yüksekliğini ekranın %90'ı ile sınırlayın */
  overflow-y: auto; /* Dikey kaydırma */
  width: 100%;
  max-width: 40rem; /* Genişliği sınırla */
  background: white;
  padding: 1.5rem;
  border-radius: 1rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
@media (max-width: 768px) {
  .modal-content {
    max-width: 90%; /* Genişlik mobilde %90 */
    max-height: 80vh; /* Yükseklik mobilde %80 */
    padding: 1rem; /* Daha dar iç boşluk */

  }
}
.input {
  height: 2.5rem; /* Daha dar giriş kutuları */
  font-size: 0.875rem; /* Yazı boyutunu küçült */
}

.form-control {
  margin-bottom: 0.5rem; /* Alanlar arasındaki boşluğu azalt */
}

/* Modal Container */
.modal-container {
  position: fixed; /* Pop-up ekranın tamamını kaplasın */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Yarı saydam arka plan */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Arkadaki içeriklerden üstte */
}

/* Pop-up içeriği */
.modal-content {
  background-color: white;
  max-height: 90vh; /* Yüksekliği sınırlayın */
  width: 100%;
  max-width: 600px;
  overflow-y: auto; /* Yalnızca pop-up içinde kaydırma yapılabilir */
  border-radius: 10px;
  padding: 20px;
}


